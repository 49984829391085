@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  margin: 0;
}
p {
  margin: 0;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #26395f;
}

::-webkit-scrollbar-thumb:hover {
  background: #26395f;
}

.page-container {
  max-width: 1200px;
  min-height: 70vh;
  padding: 20px;
  margin: 0 auto;
}
.main-page-wrapper {
  background-color: "#fdfdfd";
  padding: 48px 150px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
@media (max-width: 768px) {
  .main-page-wrapper {
    padding: 24px 24px;
  }
}
